import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import waves from '@/directive/waves'; // waves directive
import request from '@/utils/request';
import GeneralTable from '@/components/GeneralTable';
import { log } from 'util';
import checkPermission from '@/utils/permission'; // 权限判断函数

export default {
  name: 'Index',
  components: {
    GeneralTable: GeneralTable
  },
  data: function data() {
    return {
      tableName: 't_export_file',
      url: {
        getUrl: '/export-file',
        method: 'post',
        query: {}
      },
      canSearch: checkPermission('system.client.list'),
      //true,
      canAdd: false,
      //true,
      canEdit: false,
      //true,
      canDelete: true,
      //true,
      canBatchEdit: false,
      //true,
      canDownload: checkPermission('system.client.download') //true
    };
  },
  computed: {
    operation: function operation() {
      return [{
        name: this.$t('common.download'),
        method: this.downloadExcelConfirm,
        type: 'primary',
        loading: false,
        hide: function hide(item) {
          return !item.has_file || isNull(item.c_file_size);
        }
      }];
    }
  },
  created: function created() {
    sessionStorage.removeItem(window.location.href + '_LIST_QUERY');
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    // 保存离开本页面时的查询条件，当再次进入本页面时回显
    if (this.$refs.GeneralTable) {
      sessionStorage.setItem(window.location.href + '_LIST_QUERY', JSON.stringify(this.$refs.GeneralTable.getListQuery()));
    }
    next();
  },
  methods: {
    downloadExcelConfirm: function downloadExcelConfirm(index, item) {
      var __this = this;
      var newDatas = [];
      var h = this.$createElement;
      newDatas.push(h('p', null, this.$t('common.fileName') + ':' + item.c_file_name));
      newDatas.push(h('p', null, this.$t('common.fileSize') + ':' + item.c_file_size + 'K'));
      this.$confirm(h('div', null, newDatas), this.$t('common.download_confirm'), {
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(function () {
        __this.downloadExcel(index, item);
      });
    },
    getAttachmentFileName: function getAttachmentFileName(item) {
      if (item.c_file_name.split('_').length > 2) {
        return item.c_file_name.replaceAll(/_.*\./img, '.');
      } else {
        return item.c_file_name;
      }
    },
    downloadExcel: function downloadExcel(index, item) {
      var __this = this;
      this.$set(item, 'loading', true);
      this.$set(item, 'showProgress', true);
      var total = item.c_file_size * 1024;
      request({
        url: 'export-file/download-excel/' + item.c_id,
        method: 'get',
        headers: {
          'accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/octet-stream'
        },
        responseType: 'arraybuffer',
        callBack: function callBack() {
          __this.$set(item, 'loading', false);
          return false;
        },
        onDownloadProgress: function onDownloadProgress(progressEvent) {
          var loaded = progressEvent.loaded;
          var __progress = Math.round(loaded * 100 / total);
          __this.$set(item, '__progress', __progress);
        },
        attachmentFileName: __this.getAttachmentFileName(item)
      }).then(function (res) {});
    }
  }
};